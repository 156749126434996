var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('notifications',{attrs:{"group":"global","position":"top right","animation-type":"css","animation-name":"fade","speed":500,"width":"300"}}),_c('div',{attrs:{"id":"topbar"}},[(
       _vm.$route.name === 'landing' ||
       _vm.$route.name === 'register' ||
       _vm.$route.name === '404' ||
       _vm.$route.name === 'blog-home' ||
       _vm.$route.name === 'expired' ||
       _vm.$route.name === 'blog-post' ||
       _vm.$route.name === 'category' ||
       _vm.$route.name === 'naas' ||
       _vm.$route.name === 'property-tax' ||
       _vm.$route.name === 'data' ||
       _vm.$route.name === 'thanks' ||
       _vm.$route.name === 'reset-success' ||
       _vm.$route.name === 'pricing' ||
       _vm.$route.name === 'opportunities' ||
       _vm.$route.name === 'email-confirmed' ||
       _vm.$route.name === 'email-sender-confirmed' ||
       _vm.$route.name === 'login' ||
       _vm.$route.name === 'forgot' ||
       _vm.$route.name === 'api' ||
       _vm.$route.name === 'reset-password' ||
       _vm.$route.name === 'terms' ||
       _vm.$route.name === 'privacy' ||
       _vm.$route.name === 'affiliation' ||
       _vm.$route.name === 'thanks-signup' ||
       _vm.$route.name === 'thanks-retarget'        
     )?_c('landing-nav'):_vm._e(),(_vm.$route.path === '/search')?_c('search-filters'):_vm._e()],1),_c('div',{attrs:{"id":"sidebar"}},[_c('Sidebar')],1),_c('div',{attrs:{"id":"content"}},[_c('router-view',{key:_vm.$route.fullPath})],1),(_vm.loading)?_c('Spinner',{staticClass:"loading",attrs:{"size":55,"line-size":7,"line-fg-color":"#C5C5C5","speed":0.6}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }